import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import Vant from "vant";
import "vant/lib/index.css";
import "amfe-flexible";
import store from "./store";
import api from "./api";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import { Loading } from "vant";

Vue.config.productionTip = false;
Vue.prototype.$api = api;

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Loading);

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (store.state.isLogin) {
      store.commit("setLoginModalState", false);
      next();
    } else {
      store.commit("setLoginModalState", true);
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
