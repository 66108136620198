import config from "./config";

export default class User extends config {
  static login(params) {
    let path = "/api/user/register?";
    const arr = [];
    for (let key in params) {
      arr.push(`${key}=${params[key]}`);
    }
    path += arr.join("&");
    return this.comGet(path);
  }
  static sendSms(phone, sign, channelCode) {
    const path = `/api/user/sendCode?channelCode=${channelCode}&phone=${phone}&sign=${sign}`;
    return this.comGet(path);
  }
  static logout() {
    const path = `/api/user/logout`;
    return this.comGet(path);
  }
  static getDetail(hideLoading) {
    const path = `/api/user/detail`;
    return this.comGet(
      path,
      {},
      {
        hideLoading,
      }
    );
  }
  static submitForm(data) {
    const path = `/api/user/submitForm`;
    return this.comPost(path, data);
  }
  static submitCity(data) {
    const path = `/api/user/submitCity`;
    return this.comPost(path, data, {
      hideLoading: true,
    });
  }
  static getPro() {
    const path = `/api/product/list`;
    return this.comGet(path);
  }
  static submitFeed(data) {
    const path = `/api/user/submit`;
    return this.comPost(path, data);
  }
  static getOrgList(hideLoading) {
    const path = `/api/org/list`;
    return this.comGet(
      path,
      {},
      {
        hideLoading,
      }
    );
  }
  static applyOrg(id, slaveId) {
    const path = `/api/org/apply/${id}?slaveId=${slaveId}`;
    return this.comGet(path);
  }
  static getProUrl(id) {
    const path = `/api/product/getProductUrl/${id}`;
    return this.comGet(path);
  }
  static getWxUrl(){
    const path = '/api/org/getWxUrl';
    return this.comGet(path);
  }
  static callbackChannel(data) {
    const path = `/api/channel/callback`;
    return this.comPost(path, data);
  }}