import axios from "axios";
import Vue from "vue";

let apiLoading = null;
let requestCount = 0;
let ip = location.origin;

/* if (ip.includes("h5.hxzhgg.com")) {
  //ip = "https://testh5.csqianfeng.com";
  ip = "http://h5.hxzhgg.com:18081";
} */

/*if (ip.includes("http://yxh.hxzhgg.com")) {
  ip = "https://h5.hxzhgg.com";
}*/

 if (ip.includes("localhost:8080")) {
  ip = "localhost:8081";
}

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.token = token;
  }
  const { diyObj } = config;
  if (!diyObj || !diyObj.hideLoading) {
    if (requestCount === 0) {
      apiLoading = Vue.prototype.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
    }
    requestCount++;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    const { diyObj } = response.config;
    if (!diyObj || !diyObj.hideLoading) {
      setTimeout(() => {
        requestCount--;
        if (!requestCount) {
          apiLoading.close();
        }
      }, 500);
    }

    if (response.status === 200) {
      if (response.data.code === "0000") {
        return response.data;
      } else if (response.data.code === "2000") {
        location.href = "/";
      } else if (!response.data.code) {
        return response;
      } else {
        Vue.prototype.$toast({
          message: response.data.desc,
          type: "fail",
        });
        return false;
      }
    }
  },
  (error) => {
    console.log(error);
    const res = JSON.parse(JSON.stringify(error)).response;
    if (res.status === 400) {
      Vue.prototype.$toast({
        message: res.data.desc,
        type: "fail",
      });
      return false;
    } else if (res.status === 401) {
      // Vue.prototype.$toast({
      //   message: res.data.desc,
      //   type: "error"
      // });
      // document.location.pathname = "/";
    } else if (res.status === 403) {
      Vue.prototype.$toast({
        message: res.data.desc,
        type: "fail",
      });
    } else if (res.status === 500) {
      Vue.prototype.$toast({
        message: res.data.desc || res.data.error,
        type: "fail",
      });
      return false;
    } else {
      Vue.prototype.$toast({
        message: JSON.parse(JSON.stringify(error)),
        type: "fail",
      });
      return false;
    }
  }
);

export default class Config extends axios {
  static getIp() {
    return ip;
  }
  static comGet(path, params = {}, diyObj = {}) {
    return new Promise((resolve) => {
      const url = ip + path;
      this.get(url, { params, diyObj }).then((res) => {
        resolve(res);
      });
    });
  }
  static comPost(path, data, param = {}) {
    return new Promise((resolve) => {
      const url = ip + path;
      this.post(url, data, param).then((res) => {
        resolve(res);
      });
    });
  }
  static comPatch(path, data) {
    return new Promise((resolve) => {
      const url = ip + path;
      this.patch(url, data).then((res) => {
        resolve(res);
      });
    });
  }
  static comPut(path, data) {
    return new Promise((resolve) => {
      const url = ip + path;
      this.put(url, data).then((res) => {
        resolve(res);
      });
    });
  }
  static comDel(path) {
    return new Promise((resolve) => {
      const url = ip + path;
      this.delete(url).then((res) => {
        resolve(res);
      });
    });
  }
}
