import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: !!localStorage.getItem("token"),
    showLoginModal: false,
    userInfo: {},
    proName: localStorage.getItem("proName"),
  },
  mutations: {
    setLogin(state, value) {
      state.isLogin = value;
    },
    setLoginModalState(state, value) {
      state.showLoginModal = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setProName(state, value) {
      state.proName = value;
    },
  },
  actions: {
    async getUserInfo(context) {
      const res = await api.user.getDetail();
      if (res) {
        context.commit("setUserInfo", res.data);
      }
    },
  },
});
