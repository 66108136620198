import Vue from "vue";
import VueRouter from "vue-router";
import index from "./modules/index";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [{ path: "/", redirect: "/login" }, ...index],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
});

export default router;