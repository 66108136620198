export default [
  {
    path: "/login",
    component: () => import("@/pages/loginPage/loginPage.vue"),
  },
  {
    path: "/loginPre",
    component: () => import("@/pages/loginPrePage/loginPrePage.vue"),
  },
  {
    path: "/loginNew",
    component: () => import("@/pages/loginNewPage/loginNewPage.vue"),
  },
  {
    path: "/form",
    component: () => import("@/pages/formPage/formPage.vue"),
  },
  {
    path: "/formNew",
    component: () => import("@/pages/formNewPage/formNewPage.vue"),
  },
  {
    path: "/longForm",
    component: () => import("@/pages/longFormPage/longFormPage.vue"),
  },
  {
    path: "/pro",
    component: () => import("@/pages/proPage/proPage.vue"),
  },
  {
    path: "/match",
    component: () => import("@/pages/matchPage/matchPage.vue"),
  },
  {
    path: "/halfMatch",
    component: () => import("@/pages/halfMatchPage/halfMatchPage.vue"),
  },
  {
    path: "/apply",
    component: () => import("@/pages/applyPage/applyPage.vue"),
  },
  {
    path: "/applySuc",
    component: () => import("@/pages/applySucPage/applySucPage.vue"),
  },
  {
    path: "/home",
    component: () => import("@/pages/homePage/homePage.vue"),
  },
  {
    path: "/applySucDisplay",
    component: () => import("@/pages/applySucDisplayPage/applySucDisplayPage.vue"),
  },
];
