<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {
    if (this.$store.state.isLogin) {
      // this.$store.dispatch("getUserInfo");
    }
  },
  methods: {},
};
</script>
<style lang="less">
html,
body {
  height: 100%;
}
</style>
<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  height: 100%;
  background: #f5f5f5;
}
</style>
