import config from "./config";

export default class Course extends config {
  static comUpload(data) {
    let formData = new FormData();
    formData.append("file", data);
    const path = "/api/common/upload";
    return this.comPost(path, formData);
  }
  static getCityList() {
    const path = "/api/city/list";
    return this.comPost(path);
  }
  static getEnumByName(name) {
    const path = `/api/common/getEnumByName?name=${name}`;
    return this.comGet(path);
  }
  static getEnumByNameList(name, hideLoading) {
    const path = `/api/common/getEnumByNameList?name=${name}`;
    return this.comGet(
      path,
      {},
      {
        hideLoading,
      }
    );
  }
  static productUv(productId) {
    const path = `/api/chart/product/uv/${productId}`;
    return this.comGet(path);
  }
  static formUv() {
    const path = `/api/chart/form/uv`;
    return this.comGet(
      path,
      {},
      {
        hideLoading: true,
      }
    );
  }
}
